<template>
  <div :class="className">
    <div class="flex flex-wrap -mx-2">
      <div class="flex-grow px-2">
        <label :for="id" v-if="label" class="block mb-2">{{ label }}</label>
      </div>
      <div class="flex-shrink px-2">
        <tooltip v-if="tooltip">
          {{ tooltip }}
        </tooltip>
      </div>
    </div>
    <date-time-picker
      v-model="content"
      @input="handleInput"
      :format="format"
      :value-format="valueFormat"
      :constraints-format="valueFormat"
      :main-button-class="{
          'w-full py-2 px-3 border-2 rounded bg-white outline-none focus:border-black text-left': true,
          'bg-gray-300 text-gray-500': disabled,
          'border-red-600 hover:border-red-700': error,
          'border-gray-300 hover:border-gray-400': !error
        }"
      :min-date="minDate"
      :max-date="maxDate"
      :minute-step="5"
      :disabled="disabled"
      :only-time-picker="time && !date"
      :time-picker="time"
      value-type="String"
    >
      <template v-slot:choose-date>
        <span class="text-gray-400">{{ placeholder }}</span>
      </template>
      <template v-slot:date>
        <button class="bg-black rounded py-2 px-4 text-white">
          <i class="fas fa-arrow-left"></i> Set Date
        </button>
      </template>
      <template v-slot:time>
        <button class="bg-black rounded py-2 px-4 text-white">
          Set Time <i class="fas fa-arrow-right"></i>
        </button>
      </template>
    </date-time-picker>
    <div v-if="error" class="text-red-600 text-sm mt-2 font-bold">
      <i class="fas fa-exclamation-triangle"></i>
      {{ error }}
    </div>

  </div>
</template>

<script>
import randomstring from 'randomstring'
import DateTimePicker from 'vue-vanilla-datetime-picker'
export default {
  components: {
    DateTimePicker
  },
  props: {
    className: {
      type: String,
      default: 'mb-6'
    },
    label: {
      type: String
    },
    error: {
      type: String
    },
    value: {
    },
    placeholder: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String
    },
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    date: {
      type: Boolean,
      default: true
    },
    time: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: randomstring.generate(),
      content: this.value
    }
  },
  computed: {
    format () {
      if (this.date && this.time) {
        return 'dd/LL/yyyy HH:mm'
      }
      if (this.time) {
        return 'HH:mm'
      }
      return 'dd/LL/yyyy'
    },
    valueFormat () {
      if (this.date && this.time) {
        return 'yyyy-LL-dd HH:mm:ss'
      }
      if (this.time) {
        return 'HH:mm:ss'
      }
      return 'yyyy-LL-dd'
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.content)
    }
  },
  watch: {
    value: function () {
      this.content = this.value
    }
  }
}
</script>

<style lang="scss">
.datetime-picker-main {
  z-index: 2;
}
</style>