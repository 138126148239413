<template>
  <span>
    <component :is="component" @click="openModal" :btn-class="btnClass">
      <slot name="trigger"></slot>
    </component>

    <div class="fixed z-50 text-left text-base h-screen whitespace-normal" v-if="modalOpen">
      <div class="fixed inset-0 bg-black opacity-75 modal-bg" @click="closeModal"></div>
      <div class="fixed inset-0 pointer-events-none">
        <div class="h-screen overflow-auto">
          <div class="container">
            <div class="flex items-center justify-center min-h-screen">
              <div class="relative bg-white rounded shadow-lg m-6 md:mx-0 modal pointer-events-auto" :class="modalClass">
                <div
                  class="rounded-full w-6 h-6 bg-gray-300 text-gray-600 hover:text-gray-800 flex items-center justify-center absolute top-0 right-0 cursor-pointer -mt-2 -mr-2"
                  @click.prevent="closeModal"
                >
                  <i class="fas fa-times"></i>
                </div>
                <h2
                  class="bg-black text-white px-6 py-3 block w-full rounded-t"
                  v-if="heading"
                >{{ heading }}</h2>
                <div class="p-6">
                  <slot></slot>
                </div>
                <div class="py-3 px-6 bg-gray-200 rounded-b-lg" v-if="$slots.footer">
                  <slot name="footer"></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import SecondaryBtn from "@/components/SecondaryBtn";

export default {
  components: {
    SecondaryBtn
  },
  props: {
    heading: String,
    size: {
      type: String,
      default: 'md'
    },
    btnClass: {
      type: String,
      default: ''
    },
    component: {
      type: String,
      default: 'secondary-btn'
    }
  },
  data () {
    return {
      modalOpen: false
    }
  },
  computed: {
    modalClass () {
      if (this.size === 'lg') {
        return 'w-full md:w-3/4 xl:w-2/3'
      }

      return 'w-full md:w-2/3 xl:w-1/3'
    }
  },
  beforeDestroy () {
    document.body.classList.remove('overflow-hidden')
  },
  methods: {
    openModal () {
      this.modalOpen = true
      document.body.classList.add('overflow-hidden')
      this.$emit('state-changed', true)
    },
    closeModal () {
      this.modalOpen = false
      document.body.classList.remove('overflow-hidden')
      this.$emit('state-changed', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-bg {
  animation: modal-bg 0.2s;
}
.modal {
  animation: modal 0.2s;
}
@keyframes modal-bg {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
}
@keyframes modal {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
