<template>
  <button
    type="button"
    class="px-6 py-3 ml-2 rounded outline-none inline-block bg-gray-300"
    :class="className"
    @click.prevent="$emit('click')"
    :disabled="loading || disabled"
  >
    <i class="fas fa-fw fa-spin fa-sync" v-if="loading"></i>
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  props: {
    btnClass: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
      validator: function (value) {
        return ["xs", "sm", "md", "lg", "xl"].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    className: function () {
      let output = this.btnClass;

      if (this.disabled) {
        output += "text-gray-600 cursor-default";
      } else {
        output += this.loading ? " cursor-wait" : " hover:bg-gray-400";
      }

      if (this.size) {
        output += ` text-${this.size}`;
      }

      return output;
    },
  },
};
</script>